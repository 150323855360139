<template>
    <b-container class="contact">
        <b-row>
            <b-col col lg="12">
                <h1 class="py-5"><span class="font-weight-light">Impresszum</span></h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col col lg="6">
                <iframe width="100%" height="500px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=Budafer+Hungary+Zrt.&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
            </b-col>
             <b-col col lg="6">
                 <p class="budafer-underline"><b>Szolgáltató adatai:</b></p>
                  <p>
                        Szolgáltató: Budafer Hungary Zrt.<br>
                        Cégjegyzékszáma: 13-10-041693<br>
                        Céget nyilvántartó hatóság: Budapest Környéki Törvényszék Cégbírósága<br>
                        Adószáma: 25890413-2-13<br>
                        Székhelye: 2724 Újlengyel, Határ út 12.<br>
                        Fióktelep: 7200 Dombóvár, Köztársaság utca 10.<br>
                        Elérhetősége: budaferhungary@gmail.com<br>
                        Weboldal: www.acelkalkulator.hu<br>
                  </p>
                  <p class="budafer-underline"><b>Tárhelyszolgáltató adatai:</b></p>
                  <p>
                        Microsoft Ireland Operations Ltd.<br>
                        Attn: Privacy Officer<br>
                        Carmenhall Road<br>
                        Sandyford, Dublin 18, Ireland<br>
                    </p>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
     methods: {
         hideModal() {
            this.$refs['contact-modal'].hide()
        }
     }
}
</script>
<style scoped>
    .contact{
        text-align: left;
    }
    a{
        font-weight: normal;
    }
    .budafer-underline{
        font-size: 1.5rem;
        text-decoration: underline;
        text-decoration-color: #465fa9;
        text-decoration-thickness: 3px;
        text-underline-offset:10px;
    }
    .bf-button-blue {
        border-radius: 20px;
        padding: 10px 25px;
        background-color: #465da9;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border: 0;
    }
    @media (-webkit-min-device-pixel-ratio: 1.25) {
    h1{
      font-size: 2rem !important;
    }
    p{
      margin-bottom: 0.5rem !important;
    }
    .pt-25{
       padding-top: 10vh;
    }
    .py-5 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }
    .main{
        overflow-y: auto;
    }
}
@media (max-width:576px) {
    .col{
        flex-basis: auto !important;
    }
}
</style>