<template>
<b-container class="main-weight zindex-900">
    <b-row>
        <b-col col lg="3" class="text-left menu pt-3 desktoponly">
            <h3 class="text-uppercase">Termékkategóriák</h3>
            <p v-for="group in groups"  v-bind:key="group.id">
                <b>{{group.name}}</b><br>
                <a href="javascript:void(0)" v-for="item in getfiteredCategory(group.id)" v-bind:key="item.id" @click="changeCateogry(item.id)">
                    {{item.name}}<br>
                </a>
            </p>
        </b-col>
        <b-col col lg="4" class="mobileonly pt-3 text-center">
            <h3 class="text-uppercase">Termékkategóriák</h3>
            <b-form-select id="width" @change="changeCateogryMobile()" class="budafer-input" v-model="mobilemenu">
                 <b-form-select-option :value="null" disabled>Kérem válasszon</b-form-select-option>
                  <b-form-select-option-group v-for="group in groups"  v-bind:key="group.id" :label="group.name">
                        <b-form-select-option v-for="item in getfiteredCategory(group.id)" v-bind:key="item.id" :value="item.id">{{item.name}}</b-form-select-option>
                  </b-form-select-option-group >
            </b-form-select>
        </b-col>
        <b-col col lg="1" class="desktoponly"></b-col>
        <b-col class="text-left pt-3" col lg="5" v-if="prod != null">
             <h3 class="text-uppercase font-weight-bold">{{categoryData.name}}</h3>
              <img class="w-100 pl-4 pr-4" :src="getImgUrl('fekete_alap__'+categoryData.picture)">
              <h5 class="text-uppercase font-weight-bold mt-4">{{categoryData.name}} tömegkalkulátor</h5>
              <p><b v-if="thread != ''">Szálhossz: {{thread}} m<br></b></p>
                <p class="text-justify">
                    Használja az acélkalkulátort egy meghatározott méretű {{categoryData.name}} tömegének kiszámítására! Tudja meg, hogy az Ön számára szükséges szálhosszú és mennyiségű {{categoryData.name}} mekkora tömeget jelent, hogy a vas- és acéltermék szállítását is kivitelezni tudja az építkezésre. A kívánt mennyiségű {{categoryData.name}} megvásárlására egy gombnyomással ajánlatot is kérhet vállalatunktól.
                    Ha elakadt, vagy kérdése lenne (például, hogy elérhető-e a szükséges mennyiségű {{categoryData.name}} budapesti vagy dombóvári raktárunkban), vegye fel velünk a kapcsolatot!
                    Kollégáink hamarosan felkeresik Önt, hogy megválaszolják kérdéseit a vas- és acélipari termékekkel kapcsolatban!
                </p>
        </b-col>
        <b-col class="text-left mt-5 pt-4" col lg="3" v-if="prod != null">
            <b-form-group  label="Szélesség" label-for="width"  v-if="options.width.length > 1">
            <b-form-select id="width" @change="changeSelect('width')" class="budafer-input" v-model="selected.width" :options="options.width" v-if="options.width.length > 1" :disabled="select.width"></b-form-select>
            </b-form-group>
            <b-form-group  label="Magasság" label-for="height"  v-if="options.height.length > 1">
            <b-form-select id="height" @change="changeSelect('height')" class="budafer-input" v-model="selected.height" :options="options.height" v-if="options.height.length > 1" :disabled="select.height"></b-form-select>
            </b-form-group>
            <b-form-group  label="Hosszúság" label-for="length"  v-if="options.length.length > 1">
            <b-form-select id="length" @change="changeSelect('length')" class="budafer-input" v-model="selected.length" :options="options.length" v-if="options.length.length > 1" :disabled="select.length"></b-form-select>
            </b-form-group>
            <b-form-group  label="Átmérő" label-for="diameter"  v-if="options.diameter.length > 1">
            <b-form-select id="diameter" @change="changeSelect('diameter')" class="budafer-input" v-model="selected.diameter" :options="options.diameter" v-if="options.diameter.length > 1" :disabled="select.diameter"></b-form-select>
            </b-form-group>
            <b-form-group  label="Falvastagság" label-for="thickness"  v-if="options.thickness.length > 1">
            <b-form-select id="thickness" @change="changeSelect('thickness')" class="budafer-input" v-model="selected.thickness" :options="options.thickness" v-if="options.thickness.length > 1" :disabled="select.thickness"></b-form-select>
            </b-form-group>
            <b-form-group  label="Belső téglalap mérete - a" label-for="rectanglea"  v-if="options.rectanglea.length > 1">
            <b-form-select id="rectanglea" @change="changeSelect('rectanglea')" class="budafer-input" v-model="selected.rectanglea" :options="options.rectanglea" v-if="options.rectanglea.length > 1" :disabled="select.rectanglea"></b-form-select>
            </b-form-group>
            <b-form-group  label="Belső téglalap mérete - b" label-for="rectangleb"  v-if="options.rectangleb.length > 1">
            <b-form-select id="rectangleb" @change="changeSelect('rectangleb')" class="budafer-input" v-model="selected.rectangleb" :options="options.rectangleb" v-if="options.rectangleb.length > 1" :disabled="select.rectangleb"></b-form-select>
            </b-form-group>
            <b-form-group  :label="'Mennyiség ('+unit+')'" label-for="quantity">
            <b-form-input id="quantity" @keyup="calculate()" class="budafer-input" type="number" :placeholder="'mennyiség ('+unit+')'" :disabled="select.quantity" v-model="selected.quantity"></b-form-input>
            </b-form-group>
            <h4 class="text-uppercase">A kalkuláció eredménye:</h4>
            <h2 class="font-weight-bold text-uppercase mb-5 budafer-color">{{Numberformat(mass,2,3,' ',',')}} KG</h2>
            <button v-b-modal.modal-1 class="bf-button-gary" type="button">Ajánlatot kérek!</button>
            <button v-b-modal.modal-2 class="bf-button-gary mt-2" type="button">Elküldöm magamnak</button>
            <b-modal ref="contact-modal" id="modal-1" hide-footer title="Ajánlatkérés">
                <div class="bf-contact">
                     <form id="newproductform" v-on:submit.prevent="submitProduct">
                        <input class="textfield" type="text" name="name" v-model="form.name" placeholder="Név:" required=""><br>
                        <input class="textfield" type="text" name="company" v-model="form.company" placeholder="Cégnév:" required=""><br>
                        <input class="textfield" type="email" name="email" v-model="form.email" placeholder="Email Cím:" required=""><br>
                        <input class="textfield" type="tel" id="phone" name="phone" v-model="form.phone" placeholder="+36301234567" title="+36301234567, 06301234567" pattern="^\+?(\d{10,11})$" required=""><br>
                        <textarea class="textfield" name="message" v-model="form.message"></textarea><br>
                        <input name="calculated" type="hidden" :value="JSON.stringify(selected)">
                        <input name="mass" type="hidden" :value="Numberformat(mass,2,3,' ',',')">
                        <input name="unit" type="hidden" :value="unit">
                        <input name="product" type="hidden" :value="categoryData.name">
                        <input type="checkbox" v-model="form.newsletter"> Feliratkozom a hírlevélre.<br>
                        <input type="checkbox" required=""> Elolvastam és elfogadom az <a href="//budafer.hu/adatkezelesi-tajekoztato" target="_blank">adatvédelmi nyilatkozatot</a>.<br>
                        <button class="bf-button-yellow" type="submit">Ajánlatkérés elküldése</button>
                    </form>
                </div>
            </b-modal>
            <b-modal ref="contact-2-modal" id="modal-2" hide-footer title="Elküldöm magamnak">
                <div class="bf-contact">
                     <form id="newproductform" v-on:submit.prevent="submitProductToMe">
                        <input class="textfield" type="email" v-model="formtome.email" name="email" placeholder="Email Cím:" required=""><br>
                        <input name="calculated" type="hidden" :value="JSON.stringify(selected)">
                        <input name="mass" type="hidden" :value="Numberformat(mass,2,3,' ',',')">
                        <input name="unit" type="hidden" :value="unit">
                        <input name="product" type="hidden" :value="categoryData.name">
                        <input type="checkbox" required=""> Elolvastam és elfogadom az <a href="//budafer.hu/adatkezelesi-tajekoztato" target="_blank">adatvédelmi nyilatkozatot</a>.<br>
                        <button class="bf-button-yellow" type="submit">Elküldöm magamnak</button>
                     </form>
                </div>
            </b-modal>
        </b-col>
    </b-row>
</b-container>
</template>
<script>
import API from '../api'
export default {
    props: ['products','groups','prodClick'],
    created() {
        this.setupProd();
    },
    watch: { 
        prodClick: function() {
          this.setupProd();
        }
      },
    data() {
        return {
            text_replace:"betonacél",
           categoryData:null,
            prod:null,
            mobilemenu:null,
            category:'',
            mass:0,
            thread:'',
            unit:'',
            selected:{
                width:null,
                height:null,
                length:null,
                diameter:null,
                thickness:null,
                rectanglea:null,
                rectangleb:null,
                quantity:null
            },
            options:{
                width:[],
                height:[],
                length:[],
                diameter:[],
                thickness:[],
                rectanglea:[],
                rectangleb:[],
                quantity:[]
            },
            select:{
                width:false,
                height:true,
                length:true,
                diameter:true,
                thickness:true,
                rectanglea:true,
                rectangleb:true,
                quantity:true
            },
            form:{
                name:'',
                company:'',
                email:'',
                phone:'',
                message:'',
                calculated:'',
                mass:'',
                unit:'',
                product:'',
                newsletter:''
            },
            formtome:{
                email:'',
                calculated:'',
                mass:'',
                unit:'',
                product:''
            }
        }
    },
    methods: {
        setupProd(){
            let fc = this.getfiteredCategory(this.prodClick);
            this.changeCateogry(fc[0].id)
        },
        submitProduct(){
            this.form.calculated=JSON.stringify(this.selected);
            this.form.mass=this.Numberformat(this.mass,2,3,' ',',');
            this.form.unit=this.unit;
            this.form.product=this.categoryData.name;
             API.post('sendemail', this.form),
            this.hideModal();
        },
        submitProductToMe(){
            this.formtome.calculated=JSON.stringify(this.selected);
            this.formtome.mass=this.Numberformat(this.mass,2,3,' ',',');
            this.formtome.unit=this.unit;
            this.formtome.product=this.categoryData.name;
             API.post('sendemailtome', this.formtome),
            this.hideModal();
        },
         hideModal() {
            this.$refs['contact-modal'].hide();
            this.$refs['contact-2-modal'].hide()
        },
        Numberformat(number, n, x, s, c) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
            let num = number.toFixed(Math.max(0, ~~n));
            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        },
        getImgUrl(pic) {
                return require('@/assets/images/'+pic)
            },
        changeText(id)
        {
            this.text_replace = this.groups[id].name;
        },
        getfiteredCategory(id){
            let filtered = [];
            this.products.forEach(element => {
                if(element.groupid ==  id)filtered.push(element);
            });
            return filtered;
        },
        changeCateogryMobile(){
            this.changeCateogry(this.mobilemenu);
        },
        changeCateogry(id){
            this.category = id;
            this.categoryData = this.products.find(element => element.id == id);
            this.getProductData(id);
            },
            getProductData(category){
                let me = this;
                API.post('/products',{category:category})
                .then(
                                response => {
                                        me.prod = response.data;
                                        me.createData();
                                })
                .catch(function (error) {
                                throw error;
                            })

            },
            createData(){
                this.emptyData();
                this.thread = this.prod[0].thread;
                this.unit = this.prod[0].unit;
                this.createFirst();
                this.updateData(this.prod);
            },
            calculate(){
            let me = this;
                this.prod.forEach(element => {
                    let equal = true;
                    for (const [key, value] of Object.entries(element)) {
                    if(value != 0 &&  me.selected[key] !== undefined) if(me.selected[key] != value) equal = false;
                    }
                    if(equal) me.mass = element.weight * me.selected.quantity;
            });
            },
            createFirst()
            {
                let first = true;
                for (const [key, value] of Object.entries(this.prod[0])) {
                if(value != 0)
                {
                    if(this.selected[key] !== undefined && first == true) 
                    {
                        this.select[key] = false;
                        first = false;
                    }
                }
                }
            },
            changeSelect(name)
            {
            let products = [];
            this.nullselected(name);
            let me = this;
            this.nextForm(name);
            this.prod.forEach(element => {
                let equal = true;
                for (const [key, value] of Object.entries(me.selected)) {
                if(value != null && key !== 'quantity')
                {
                    if(element[key] != value) equal = false;
                }
                }
                if(equal)
                {
                    products.push(element);
                }
            });
            this.updateData(products, name);
            if(this.mass != 0) this.calculate();
            },
            nullselected(name)
            {
            let nullcheck = false
                for (const [key, value] of Object.entries(this.selected)) {
                if(value != null){
                    if(nullcheck && key !== 'quantity')  this.selected[key] = null;  
                    if(key == name ) nullcheck = true;
                }
                }
            },
            nextForm(name){
            let now = false;
                for (const [key, value] of Object.entries(this.select)) {
                if(now && key == 'quantity'){
                    this.select.quantity = false;
                    now = false;
                }
                if(now && this.options[key].length > 1) {
                    this.select[key] = false;
                    now = false;
                }
                if(key == name && value != null) now = true;
                }
            },
            updateData(prod, name = null){
            let data = {
                width:null,
                height:null,
                length:null,
                diameter:null,
                thickness:null,
                rectanglea:null,
                rectangleb:null
            };
            if(name == null) data = this.updateDatafirst(prod,data);
            if(name != null) data = this.updateByName(prod,data, name);
            this.updateSelector(data);
            },
            updateDatafirst(prod,data)
            {
                prod.forEach(element => {
                for (const [key, value] of Object.entries(element)) {
                if(value != 0)
                {
                    if(data[key] !== undefined) 
                    {
                        if(data[key] == null)
                        {
                            data[key] = [value];
                        }else
                        {
                            if(!data[key].includes(value)) data[key].push(value);
                        }
                    }
                }
                }
            });
            return data;
            },
            updateByName(prod, data, name){
                prod.forEach(element => {
                let thisprop = false;
                for (const [key, value] of Object.entries(element)) {
                if(value != 0)
                {
                    if(data[key] !== undefined && thisprop) 
                    {
                        if(data[key] == null)
                        {
                            data[key] = [value];
                        }else
                        {
                            if(!data[key].includes(value)) data[key].push(value);
                        }
                    }
                    if(key == name) thisprop = true;
                }
                }
            });
            return data;
            },
            updateSelector(data)
            {
            let me = this;
                for (const [key, value] of Object.entries(data)) {
                if(value != null){
                    value.sort((a, b) => a - b);
                    let first = me.options[key][0];
                    me.options[key] = [];
                    me.options[key].push(first);
                    value.forEach(element => {
                        me.options[key].push({ value: element, text: element+' mm'})
                    });
                }
                }
            },
            emptyData(){
            this.selected={
                    width:null,
                    height:null,
                    length:null,
                    diameter:null,
                    thickness:null,
                    rectanglea:null,
                    rectangleb:null,
                    quantity:null
                    };
            this.mass = 0;
            this.options={
                            width:[{ value: null, text: 'Szélesség', disabled: true }],
                            height:[{ value: null, text: 'Magasság',disabled: true}],
                            length:[{ value: null, text: 'Hosszúság',disabled: true}],
                            diameter:[{ value: null, text: 'Átmérő',disabled: true}],
                            thickness:[{ value: null, text: 'Falvastagság',disabled: true}],
                            rectanglea:[{ value: null, text: 'Belső téglalap mérete - a',disabled: true}],
                            rectangleb:[{ value: null, text: 'Belső téglalap mérete - b',disabled: true}]
            };
            this.select={
                        width:true,
                        height:true,
                        length:true,
                        diameter:true,
                        thickness:true,
                        rectanglea:true,
                        rectangleb:true,
                        quantity:true
                    }
            }
        }
}
</script>
<style>
    .main-weight .menu{
        background-color: white;
        border-radius: 0 0 1rem 1rem;
    }
    .main-weight h3{
        font-size: 1.5rem;
    }   
    .main-weight a{
        text-decoration: none;
        color: black;
        font-weight: normal;
        font-size: 0.8rem;
    }
    .main-weight h4{
        font-size: 1.2rem;
        font-weight: bold;
    }
    .budafer-input{
        border:solid black 1px !important;
        border-radius:2rem !important;
        height:3rem !important;
        font-weight: bold !important;
    }
    .bf-button-gary {
        border-radius: 20px;
        padding: 3px 15px;
        background-color: #000;
        color: #fff;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        border: 0;
        width: 100%;
    }
     .bf-contact .textfield, .bf-contact textarea {
        width: 100%;
        border-radius: 20px;
        padding: 5px 10px;
        border: 1px solid;
        margin-bottom: 10px;
    }
    .bf-contact textarea {
        resize: none;
        height: 270px;
    }
    .bf-contact button {
        margin-top: 30px;
        border-radius: 20px;
        padding: 3px 15px;
        background-color: #ffa21c;
        color: #000;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        border: 0;
    }
    .zindex-900{
        z-index: 900;
        position:relative;
    }
</style>