<template>
    <b-container>
        <b-row>
            <b-col col lg="12">
                <h1 class="py-5">Keresés</h1>
            </b-col>
        </b-row>
        <b-row class="pb-5">
             <b-col col lg="12" class="text-center">
                    <b-form-input @change="search()"  id="product" type="text" placeholder="Keresendő termék" v-model="searchText"></b-form-input>
            </b-col>
        </b-row>
        <b-row class="text-left desktoponly" v-for="product in prodElements" v-bind:key="product.id">
                <b-col col lg="3">
                         <img class="w-75" :src="getImgUrl('fekete_alap__'+product.picture)">
                </b-col>
                <b-col class="pt-5 mt-5" col lg="7">
                    <h3>{{product.name}}</h3>
                </b-col>
                <b-col class="pt-5 mt-5" col lg="2">
                    <button @click="changePage(product.groupid)" class="bf-button-gary" type="button">Kalkulálok</button>
                </b-col>
        </b-row>
         <b-row class="text-left mobileonly pb-5" v-for="product in prodElements" v-bind:key="product.id">
                <b-col col sm="6">
                    <h3>{{product.name}}</h3>
                </b-col>
                <b-col col sm="2">
                    <button @click="changePage(product.groupid)" class="bf-button-gary" type="button">Kalkulálok</button>
                </b-col>
        </b-row>
</b-container>
</template>
<script>
export default {
    props: ['products','prodClick','page'],
    created() {},
    data() {
        return {
            searchText:'',
            prodElements:[]
        }
    },
    methods: {
         getImgUrl(pic) {
                return require('@/assets/images/'+pic)
            },
            search(){
                console.log(this.searchText);
                this.prodElements = [];
                 this.products.forEach(element => {
                    if(element.name.toUpperCase().search(this.searchText.toUpperCase()) >= 0) this.prodElements.push(element);
                });
                console.log(this.prodElements);
            },
            changePage(id)
            {
                this.$emit('update:prodClick', id);
                this.$emit('update:page', 'weight');
            }
    }
}
</script>
<style scoped>
    .a-none img{
        background:none !important;
}
</style>