<template>
  <div id="app">
  <div class="header w-100 py-3 desktoponly">
    <b-container>
         <b-row>
           <b-col col lg="3">
              <img @click="page = 'main'" class="w-75" :src="require('@/assets/images/logo.png')">
           </b-col>
           <b-col col lg="8">
             <b-row>
               <b-col class="text-center" col lg="2" v-for="group in groups" v-bind:key="group.id">
                 <a href="javascript:void(0)" @click="clickMenu(group.id)">
                  <img class="w-75" :src="getImgUrl(group.image)">
                  <p class="text-uppercase font-italic">{{group.name}}</p>
                  </a>
               </b-col>
             </b-row>
           </b-col>
           <b-col col lg="1">
                <a href="http://acel.budafer.hu" target="_blank"><div class="budafer py-1 mb-1 pl-1"><img :src="require('@/assets/images/budafer-logo.png')"></div></a>
                <div class="phone py-1 mb-1  pl-1" @click="page = 'contact'"><img :src="require('@/assets/images/phone.png')"></div>
                <div class="search py-1  pl-1" @click="page = 'search'"><img :src="require('@/assets/images/search.png')"></div>
           </b-col>
         </b-row>
    </b-container>
  </div>
  <div class="mobileonly">
    <b-navbar toggleable type="light" variant="white">
         <b-navbar-brand href="#" @click="page = 'main'">
          <img :src="require('@/assets/images/logo.png')" class="w-50">
        </b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="list"></b-icon>
            <b-icon v-else icon="list"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav class="ml-auto text-center">
              <b-nav-item v-for="group in groups" v-bind:key="group.id" href="javascript:void(0)" @click="clickMenu(group.id)">
                {{group.name}}
              </b-nav-item>
          </b-navbar-nav>
          <hr>
          <b-navbar-nav class="ml-auto text-center">
            <b-nav-item href="http://budafer.hu" target="_blank">Budafer</b-nav-item>
            <b-nav-item href="javascript:void(0)" @click="page = 'contact'">Kapcsolat</b-nav-item>
            <b-nav-item href="javascript:void(0)" @click="page = 'search'">Keresés</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>
  </div>
   <div class="main"> 
    <Main v-if="page == 'main'"
      :groups="groups"
      :page.sync = "page"
      :prodClick.sync="prodClick"
      >
    </Main>
    <Weight
      v-if="page == 'weight'"
      :groups="groups"
      :products="products"
      :prodClick="prodClick"
     >
    </Weight>
    <Contact v-if="page == 'contact'">
    </Contact>
    <Impressum v-if="page == 'impressum'">
    </Impressum>
    <Search v-if="page == 'search'"
      :products="products"
       :page.sync = "page"
      :prodClick.sync="prodClick"
    >
    </Search>
   </div>
    <div class="footer">
      <div class="crosslink desktoponly">
        <a  class="footer_link" href="https://ereszkalkulator.hu">
          <p><span>Próbálja ki</span><br>ereszkalkulátorunkat is!</p>
        </a>
      </div>
      <ul>
        <li>Budafer Acél Kis- és Nagykereskedő Zrt. {{new Date().getFullYear()}} </li>
        <li class="li-sec pointer" @click="page = 'impressum'"> * Impresszum</li>
        <li class="li-sec"> * <a class="footer_link" href="https://budafer.hu/pdf/Adatkezelési tájékoztató_Budafer_2021_acelkalkulator.pdf" target="_blank">Adatvédelmi nyilatkozat</a></li>
      </ul>
      </div>
  </div>
</template>

<script>
import Main from './components/Main.vue'
import Weight from './components/Weight.vue'
import Contact from './components/Contact.vue'
import Search from './components/Search.vue'
import Impressum from './components/Impressum.vue'
import API from './api' 
export default { 
  name: 'App',
  components: {
    Main,
    Weight,
    Contact,
    Search,
    Impressum
  },
   data() {
    return{
      products:[],
      groups:[],
      page:'main',
      prodClick:0
    }
  },
  created() {
    this.getProductsCategory();
    this.getCategoryGroups();
  },
   methods: {
     clickMenu(id){
        this.prodClick = id;
        this.page = "weight";
        
     },
     getImgUrl(pic) {
       return require('@/assets/images/'+pic)
       },
     getProductsCategory(){
        let me = this;
                API.get('/categories')
                    .then(
                        response => {
                                me.products = response.data;
                        })
                    .catch(function (error) {
                        throw error;
                    })
     },
      getCategoryGroups(){
        let me = this;
                API.get('/groups')
                    .then(
                        response => {
                                me.groups = response.data;
                        })
                    .catch(function (error) {
                        throw error;
                    })
     }
   }
}
</script>
<style>
body{
  overflow-y: hidden;
  background-image: url('~@/assets/images/background.png');
}
 #app{
    font-size: 0.8rem;
    color: black;
    font-family: 'Roboto', sans-serif !important;
  }
  .pointer
  {
    cursor: pointer;
  }
.header{
  border-top: solid 0.4rem #707070;
  font-size: 0.6rem;
  background-color: white;
}
.header p{
  padding: 0;
  margin:0;
}
.header a{
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.header a:hover{
  text-decoration: none;
  color:#465f9a;
}
.footer{
  position: absolute;
  bottom:0;
  width:100%;
  color:white;
}
.footer .crosslink{
  background-color: black;
  float:right;
  padding:1rem 1rem 1rem 2rem;
  border-radius: 3rem 0 0 0;
  text-align: center;
  font-size: 1rem;
  cursor:pointer;
}
.footer .crosslink span{
  font-size: 1.2rem;
}
.footer .crosslink p{
  padding:0;
  margin:0;
}
.footer ul{
  text-align: center;
  float:left;
  width:100%;
  background-color: #465f9a;
  border-top: solid 0.3rem black;
  margin-bottom:0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.footer ul li{
  display: inline-block;
}
.footer ul .li-sec{
  padding-left:0.4rem;
}
.footer .footer_link{
  color:white;
  text-decoration: none;
}
.footer .footer_link:hover{
  color:white;
  text-decoration: none;
}
.budafer{
  background-color: #465fa9;
  cursor: pointer;
}
.budafer:hover{
  margin-left:-1rem;
}
.phone{
  background-color: gray;
  cursor: pointer;
}
.phone:hover{
  margin-left:-1rem;
}
.search{
  background-color: black;
  cursor: pointer;
}
.search:hover{
  margin-left:-1rem;
}
.main{
    overflow-y: auto;
}
.mobileonly{
        display:none !important;
      }
@media (-webkit-min-device-pixel-ratio: 1.25) {
 #app{
        font-size: 0.8rem !important;
      }
    h1{
      font-size: 2rem !important;
    }
    p{
      margin-bottom: 0.5rem !important;
    }
    .pt-25{
       padding-top: 10vh;
    }
    .py-5 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }
    .footer .crosslink{
        font-size: 0.6rem;
    }
    .footer .crosslink span{
      font-size: 0.8rem;
    }
}
 @media (max-width:576px) {
      .desktoponly{
        display: none !important;
      }
      .mobileonly{
        display: block !important;
      }
      h1{
        font-size: 1.6rem !important;
      }
      .main{
        padding-left: 1rem;
        padding-right: 1rem;
        height: 75vh;
      }
    }
</style>
