<template>
    <b-container class="main">
        <b-row class="pt-25">
            <h1 class="pb-5">
                Próbálja ki <b>acélkalkulátorunkat!</b>
            </h1>
        </b-row>
        <b-row>
            <b-col class="text-center" col lg="2" v-for="(group, index) in groups" v-bind:key="group.id">
                 <a href="javascript:void(0)" @mouseover="changeText(index)" @click="changePage(group.id)">
                  <img class="w-100 mb-3" :src="getImgUrl(group.image)">
                  <p class="text-uppercase font-italic">{{group.name}}</p>
                  </a>
            </b-col>
        </b-row>
        <b-row class="pt-5 text-justify">
            <p>Használja az acélkalkulátort, és számítsa ki, hogy a beszerzendő acéltermékek mekkora tömeget jelentenek! Az össztömeg meghatározásával egyúttal azt is pontosabban láthatja, hogy az adott vas- és acéltermékek vásárlása esetén milyen eszközre lesz szüksége az elszállításukhoz. Amennyiben segítségre van szüksége, vegye fel kollégáinkkal a kapcsolatot, készségesen állnak rendelkezésére a kínálatunkban elérhető termékek, egyedi igények vagy a megvásárolt vas- és acéltermékek szállítása kapcsán is.</p>
        </b-row>
    </b-container>
</template>
<script>
export default {
    props: ['groups','page','prodClick'],
    data() {
        return {
            text_replace:"betonacél"
        }
    },
    methods: {
        getImgUrl(pic) {
                return require('@/assets/images/'+pic.replace('fekete','feher'))
            },
        changeText(id)
        {
            this.text_replace = this.groups[id].name;
        },
        changePage(id)
        {
            this.$emit('update:prodClick', id);
            this.$emit('update:page', 'weight');
        }
    }
}
</script>
<style>
.main{
    text-align: center;
    height: 80vh;
}
.pt-25{
    padding-top: 20vh;
}
h1{
    text-align: center;
    text-transform: uppercase;
    width:100%;
}
.main a{
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.main a:hover{
    text-decoration: none;
  color:#465f9a;
}
.main a img{
    background-color: #465fa9;
    border-radius: 1rem;
    padding: 1rem;
}
@media (-webkit-min-device-pixel-ratio: 1.25) {
   h1{
      font-size: 2rem !important;
    }
    p{
      margin-bottom: 0.5rem !important;
    }
    .pt-25{
       padding-top: 10vh;
    }
    .py-5 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }
    .main{
        overflow-y: auto;
        max-height: 75vh;
    }
}
</style>