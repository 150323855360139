<template>
    <b-container class="contact">
        <b-row>
            <b-col col lg="12">
                <h1 class="py-5"><span class="font-weight-light">vegye fel velünk</span><b> a kapcsolatot!</b></h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col col lg="6">
                <iframe width="100%" height="500px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10785.26268652101!2d18.8328636!3d47.4837635!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb434c28a0a45bd92!2sLogiCube%20Park!5e0!3m2!1shu!2shu!4v1673806817471!5m2!1shu!2shu"></iframe>
            </b-col>
             <b-col col lg="6">
                 <p class="budafer-underline"><b>Budafer Hungary Zrt.</b></p>
                  <p><strong>Iroda</strong></p>
                  <p>2051 Biatorbágy, Bánki Donát út 6. LogiCube Ipari Park</p><br>
                  <p><strong>Raktár 1:</strong></p>
                  <p>1211 Budapest, Weiss Manfréd út 5.</p><br>
                  <p><strong>Raktár 2:</strong></p>
                  <p>7200 Dombovár, Köztársaság u. 15.</p><br>
                  <p><strong>Email:&nbsp;</strong><a href="mailto:info@budafer.hu">info@budafer.hu</a></p>
                  <p><span style="background-color: initial; color: rgb(54, 54, 54); font-weight: 700; font-style: normal;">Telefonszám: </span>
                  <a href="tel:+3619988899" style="background-color: initial;">+36 1 99 888 99</a><br></p>
                <button v-b-modal.modal-1 class="bf-button-blue" type="button">Írjon nekünk üzenetet!</button>
            </b-col>
        </b-row>
        <b-modal ref="contact-modal" id="modal-1" hide-footer title="Kapcsolat">
                <div class="bf-contact">
                     <form id="newproductform" v-on:submit.prevent="submitContact">
                        <input class="textfield" type="text" name="name" v-model="form.name" placeholder="Név:" required=""><br>
                        <input class="textfield" type="text" name="company" v-model="form.company" placeholder="Cégnév:" required=""><br>
                        <input class="textfield" type="email" name="email" v-model="form.email" placeholder="Email Cím:" required=""><br>
                        <input class="textfield" type="tel" id="phone" name="phone" v-model="form.phone" placeholder="+36301234567" title="+36301234567, 06301234567" pattern="^\+?(\d{10,11})$" required=""><br>
                        <textarea class="textfield" name="message" v-model="form.message"></textarea><br>
                        <input type="checkbox" name="newsletter" v-model="form.newsletter"> Feliratkozom a hírlevélre.<br>
                        <input type="checkbox" required=""> Elolvastam és elfogadom az <a href="//budafer.hu/adatkezelesi-tajekoztato" target="_blank">adatvédelmi nyilatkozatot</a>.<br>
                        <button class="bf-button-yellow" type="submit">Üzenet elküldése</button>
                     </form>
                </div>
            </b-modal>
    </b-container>
</template>
<script>
import API from '../api'
export default {
      data() {
        return {
            form:{
                name:'',
                company:'',
                email:'',
                phone:'',
                message:'',
                newsletter:''
            },
        }
      },
     methods: {
         submitContact(){
                 API.post('sendcontact', this.form),
            this.hideModal();
         },
         hideModal() {
            this.$refs['contact-modal'].hide()
        }
     }
}
</script>
<style scoped>
    .contact{
        text-align: left;
    }
    a{
        font-weight: normal;
    }
    .budafer-underline{
        font-size: 1.5rem;
        text-decoration: underline;
        text-decoration-color: #465fa9;
        text-decoration-thickness: 3px;
        text-underline-offset:10px;
    }
    .bf-button-blue {
        border-radius: 20px;
        padding: 10px 25px;
        background-color: #465da9;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border: 0;
    }
    @media (-webkit-min-device-pixel-ratio: 1.25) {
    h1{
      font-size: 2rem !important;
    }
    p{
      margin-bottom: 0.5rem !important;
    }
    .pt-25{
       padding-top: 10vh;
    }
    .py-5 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }
    .main{
        overflow-y: auto;
    }
}
@media (max-width:576px) {
    .col{
        flex-basis: auto !important;
    }
}
</style>